<template>
  <div class="com-product-ProcessStep text-center">
    <img src="../../../public/img/product/提交订单@2x.png" v-if="step === 1" />
    <img src="../../../public/img/product/支付@2x.png" v-else-if="step === 2" />
    <img src="../../../public/img/product/下单成功@2x.png" v-else-if="step === 3" />
  </div>
</template>
<script>
export default {
  props: {
    step: Number,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.com-product-ProcessStep {
  padding-top: 58px;
  padding-bottom: 64px;
  img {
    height: 70px;
  }
}
</style>